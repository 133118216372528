const getTime = () => {
  return new Date().getTime();
};

// eslint-disable-next-line prefer-named-capture-group
const AppleWebKit = /AppleWebKit\/([0-9]+)(?:\.([0-9]+))(?:\.([0-9]+))/;
const SUPPORTS_NATIVE_FONT_LOADING = Boolean(document['fonts']);
const DEFAULT_TIMEOUT = 3000;
class Observer {
  constructor(family, descriptors) {
    this.family = family;
    this.stretch = (descriptors === null || descriptors === void 0 ? void 0 : descriptors.stretch) || 'normal';
    this.weight = (descriptors === null || descriptors === void 0 ? void 0 : descriptors.weight) || 'normal';
    this.stretch = (descriptors === null || descriptors === void 0 ? void 0 : descriptors.stretch) || 'normal';
  }
  load(params) {
    if (!SUPPORTS_NATIVE_FONT_LOADING) {
      throw new Error('the brower doe not support native font loading');
    }
    if (this.hasSafari10Bug()) {
      throw new Error('this library does\'t support the Safari10 now');
    }
    const testString = (params === null || params === void 0 ? void 0 : params.text) || 'BESbswy';
    const timeoutValue = (params === null || params === void 0 ? void 0 : params.timeout) || DEFAULT_TIMEOUT;
    let timeoutId;
    const start = getTime();
    const loader = new Promise((resolve, reject) => {
      const check = () => {
        const now = getTime();
        if (now - start >= timeoutValue) {
          reject(new Error(String(timeoutValue) + 'ms timeout exceeded'));
        } else {
          document['fonts'].load(this.getStyle('"' + this.family + '"'), testString).then(fonts => {
            if (fonts.length >= 1) {
              resolve();
            } else {
              setTimeout(check, 25);
            }
          }, reject);
        }
      };
      check();
    });
    const timer = new Promise((resolve, reject) => {
      timeoutId = setTimeout(() => {
        reject(new Error(String(timeoutValue) + 'ms timeout exceeded'));
      }, timeoutValue);
    });
    return new Promise((resolve, reject) => {
      Promise.race([timer, loader]).then(() => {
        clearTimeout(timeoutId);
        resolve();
      }, reject);
    });
  }
  getStyle(family) {
    return [this.style, this.weight, this.supportStretch() ? this.stretch : '', '100px', family].join(' ');
  }
  supportStretch() {
    const div = document.createElement('div');
    try {
      div.style.font = 'condensed 100px sans-serif';
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return div.style.font !== '';
  }
  getUserAgent() {
    return window.navigator.userAgent;
  }
  getNavigatorVendor() {
    return window.navigator.vendor;
  }
  hasSafari10Bug() {
    if (/Apple/.test(this.getNavigatorVendor())) {
      const match = AppleWebKit.exec(this.getUserAgent());
      return Boolean(match) && parseInt(match[1], 10) < 603;
    }
    return false;
  }
}

/* eslint-disable max-len, no-unused-vars, callback-return */
const style = 'max-width:none;display:inline-block;position:absolute;height:100%;width:100%;overflow:scroll;font-size:16px;';
class Ruler {
  constructor(text) {
    this.lastOffsetWidth = -1;
    this.element = document.createElement('div');
    this.element.setAttribute('aria-hidden', 'true');
    this.element.appendChild(document.createTextNode(text));
    this.collapsible = document.createElement('span');
    this.expandable = document.createElement('span');
    this.collapsibleInner = document.createElement('span');
    this.expandableInner = document.createElement('span');
    this.collapsible.setAttribute('style', style);
    this.expandable.setAttribute('style', style);
    this.collapsibleInner.setAttribute('style', style);
    this.expandableInner.setAttribute('style', 'display:inline-block;width:200%;height:200%;font-size:16px;max-width:none;');
    this.collapsible.appendChild(this.collapsibleInner);
    this.expandable.appendChild(this.expandableInner);
    this.element.appendChild(this.collapsible);
    this.element.appendChild(this.expandable);
  }
  setFont(font) {
    if (font) {
      this.element.setAttribute('style', this.element.style + `max-width:none;min-width:20px;min-height:20px;display:inline-block;overflow:hidden;overflow:hidden;position:absolute;width:auto;margin:0;padding:0;top:-999px;white-space:nowrap;font-synthesis:none;font: ${font};`);
    }
  }
  getWidth() {
    return this.element.offsetWidth;
  }
  setWidth(width) {
    this.element.style.width = `${width}px`;
  }
  onScroll(callback) {
    if (this.reset() && this.element.parentNode !== null) {
      callback(this.lastOffsetWidth);
    }
  }
  onResize(callback) {
    const onScroll = () => {
      this.onScroll(callback);
    };
    this.collapsible.addEventListener('scroll', onScroll);
    this.expandable.addEventListener('scroll', onScroll);
    this.reset();
  }
  reset() {
    const offsetWidth = this.getWidth();
    const _with = offsetWidth + 100;
    this.expandableInner.style.width = `${_with}px`;
    this.expandable.scrollLeft = _with;
    this.collapsible.scrollLeft = this.collapsible.scrollWidth + 100;
    if (this.lastOffsetWidth !== offsetWidth) {
      this.lastOffsetWidth = offsetWidth;
      return true;
    }
    return false;
  }
}
export { Observer, Ruler };